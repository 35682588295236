import React, { PropsWithChildren, PureComponent } from 'react';

import Transition from 'react-transition-group/Transition';

import styled from 'styled-components';

type AccordionListProps = {
  className?: string;
  on?: boolean;
  duration?: number;
};

class AccordionList extends PureComponent<
  PropsWithChildren<AccordionListProps>
> {
  handleEntering = (elem?: HTMLDivElement) => {
    elem.style.height = `${elem.scrollHeight}px`;
  };

  handleExit = (elem?: HTMLDivElement) => {
    elem.style.height = '0';
  };

  render() {
    const { on, children, className } = this.props;

    return (
      <Transition
        in={on}
        timeout={this.props.duration}
        onEntering={this.handleEntering}
        onExit={this.handleExit}
        mountOnEnter
        unmountOnExit
        appear
      >
        {() => <div className={className}>{children}</div>}
      </Transition>
    );
  }
}

const styledAccordionList = styled(AccordionList)`
  position: relative;
  transition: height ${props => props.duration}ms ease-in-out;
  height: 0;
  overflow: hidden;
`;

export default styledAccordionList;
