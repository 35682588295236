import React, { PropsWithChildren, PureComponent } from 'react';

import styled from 'styled-components';

const Container = styled.div`
  -webkit-tap-highlight-color: transparent;

  &:focus {
    outline: none;
  }

  :hover {
    cursor: pointer;
  }
`;

type AccordionValueProps = {
  onChange?: () => void;
  className?: string;
};

class AccordionValue extends PureComponent<
  PropsWithChildren<AccordionValueProps>
> {
  render() {
    return (
      <Container className={this.props.className} onClick={this.props.onChange}>
        {this.props.children}
      </Container>
    );
  }
}

export default AccordionValue;
