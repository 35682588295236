import React, { PropsWithChildren, PureComponent } from 'react';

type Payload = {
  on: boolean;
  onChange: () => void;
  duration: number;
};

type AccordionProps = {
  children?: ({ on, onChange, duration }: Payload) => React.ReactNode;
  on?: boolean;
  duration?: number;
};

class Accordion extends PureComponent<AccordionProps> {
  static defaultProps = {
    on: false,
    duration: 500,
  };

  state = {
    on: this.props.on,
  };

  onChange = () => {
    this.setState((state: { on: boolean }) => ({
      on: !state.on,
    }));
  };

  render(): React.ReactNode {
    return this.props.children({
      on: this.state.on,
      onChange: this.onChange,
      duration: this.props.duration,
    });
  }
}

export default Accordion;
